import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Helmet } from "react-helmet";

import config from '../config'

import {
  AkContainerTitle,
} from '@atlaskit/navigation';
import Button, { ButtonGroup } from '@atlaskit/button';
import EditIcon from '@atlaskit/icon/glyph/edit';
import CheckIcon from '@atlaskit/icon/glyph/check';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import AkPageHeader from '@atlaskit/page-header';

import FlexContainer, { Flex } from '../components/FlexContainer';
import RequireRole from '../components/RequireRole';

import { isEmbedded } from '../utils/PageHelper';

import appicon from '../images/appicon.png';


const EditButtonIcon = <EditIcon label="Edit" />
const DoneButtonIcon = <CheckIcon label="Done" />

const breadcrumbs = {
  home: { text: 'Home', href: '/' },
  admin: { text: 'Admin', href: '/' },
  courses: { text: 'Courses', href: '/courses' },
  invoices: { text: 'Invoices', href: '/invoices' },
  regs: { text: 'Registrations', href: '/regs' },
  settings: { text: 'Settings', href: '/settings' },
}

function getBreadcrumbs(path='') {
  return (
    <BreadcrumbsStateless onExpand={() => {}}>
      {path.split('/').map(key => (
        <BreadcrumbsItem key={key} component={RouterLink} {...breadcrumbs[key]} />
      ))}
    </BreadcrumbsStateless>
  )
}

const Watermark = styled.div`
  display: inline-block;
  pointer-events: none;
`

type RouterLinkProps = {
  children: Node,
  className: string,
  href: Link,
  onMouseEnter: Event,
  onMouseLeave: Event,
}
class RouterLink extends React.PureComponent<RouterLinkProps, {}> {
  render() {
    const {
      children,
      className,
      href,
      onMouseEnter,
      onMouseLeave,
    } = this.props

    return (
      <Link
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        to={href}
      >
        {children}
      </Link>
    )
  }
}

class PageHeader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    titleEl: PropTypes.object,
    showTitle: PropTypes.bool,
    breadcrumbs: PropTypes.string,
    // From withRouter
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      isEmbedded: isEmbedded(props),
      isEdit: false,
    }
  }

  toggleEdit = () => {
    const isEdit = !this.state.isEdit
    this.setState({
      isEdit: isEdit
    })
    this.props.onEdit && this.props.onEdit(isEdit)
  }

  actionsContent = () => {
    const { isEdit } = this.state
    return (
      <div className="no-print">
        <ButtonGroup>
          <RequireRole role="admin">
            {isEdit ? (
              <Button iconBefore={DoneButtonIcon} onClick={this.toggleEdit}>Done editing</Button>
            ) : (
              <Button iconBefore={EditButtonIcon} onClick={this.toggleEdit}>Edit</Button>
            )}
          </RequireRole>
        </ButtonGroup>
        <RequireRole role="admin">
          {isEdit && (
            <ul>
              <li>Database urls</li>
              {
                Object.values(this.props.firebase.listeners.byId)
                .map(el => el.path)
                .filter((el) => el.indexOf('@') === -1)
                .filter((el, index, arr) => arr.indexOf(el) === index)
                .reverse()
                .map(path => (
                  <li key={path}><a target="_blank" href={`${config.firebase.databaseAdminURL}/${path}`}>{path}</a></li>
                ))
              }
            </ul>
          )}
        </RequireRole>
      </div>
    )
  }

  bottomBarContent = (
    <FlexContainer>
      <Flex grow={1}>
        {this.props.filters||''}
      </Flex>
    </FlexContainer>
  )

  isBottomBarEnabled() {
    return !!this.props.filters
  }

  render() {
    const {
      showTitle=true, title, titleEl,
      company_info,
      marginTop='3em',
    } = this.props
    const path = this.props.breadcrumbs
    return (
      <>
        <Helmet>
          <title>{(title ? title + ' | ' : '') + company_info.site_name}</title>
        </Helmet>
        <div style={{marginTop}}></div>
        {this.state.isEmbedded && (
          <Watermark>
            <AkContainerTitle
              text="Black Pepper Swing"
              subText="Portal"
              icon={<img alt="logo" src={appicon} />}
            />
          </Watermark>
        )}
        <FlexContainer style={{zIndex: 20, position: 'relative'}}>
          <Flex grow={1}>
            <AkPageHeader
              breadcrumbs={!this.state.isEmbedded && getBreadcrumbs(path)}
              actions={!this.state.isEmbedded && this.actionsContent()}
              bottomBar={this.isBottomBarEnabled() && this.bottomBarContent}
            >
              {showTitle && (titleEl || title)}
            </AkPageHeader>
          </Flex>
        </FlexContainer>
      </>
    )
  }
}

export default withRouter(compose(
  connect(({firebase, firebase: {data}}) => {
    return {
      firebase,
      company_info: data.company_info
    }
  })
)(PageHeader))
