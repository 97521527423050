/**
 * Dynamically insert external scripts to the page.
 * Such as Facebook Pixel and Freshdesk Widget.
 */


export function initExternalScripts({
  cookies=true,
}={}) {
  let scripts = []
  if (cookies) {
    scripts = [...scripts, freshdeskWidget, facebookPixel, gtmHead, gtmBody, gtag]
  }
  scripts.forEach(([placement, html]) => {
    let parent = document.querySelector(placement)
    let widget = document.createElement('div')
    widget.innerHTML = html
    widget.children.forEach(el => {
      let w = document.createElement(el.tagName)
      w.innerHTML = el.innerHTML
      el.attributes.forEach(attr => {
        w.setAttribute(attr.nodeName, attr.nodeValue)
      })
      parent.appendChild(w)
    })
  })
}

const freshdeskWidget = ['body', `
<!-- Freshdesk widget -->
<style>
  #freshworks-container #launcher-frame {
    right: -21px !important;
    bottom: 8px !important;
  }
</style>
<script>
  window.fwSettings={
    'widget_id':42000000080
  };
  !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
</script>
<script type='text/javascript' src='https://widget.freshworks.com/widgets/42000000080.js' async defer></script>
`]

const facebookPixel = ['head', `
<!-- Facebook Pixel Code -->
<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '599003704194136');
  fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=599003704194136&ev=PageView&noscript=1"
/></noscript>
<!-- End Facebook Pixel Code -->
`]

const gtag = ['head', `
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-105216424-2"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-105216424-2');
</script>
`]

const gtmHead = ['head', `
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TTXB82HZ');</script>
<!-- End Google Tag Manager -->
`]

const gtmBody = ['body', `
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TTXB82HZ"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
`]
